import {setFieldVisibility} from "../../../../apps/KpModule/actions";
import _ from "lodash";
import {getDataListList} from "../../../../apps/KpModule/selectors";
import {translateName} from "../../../../utils/i18n";

export const entity1 = {
    name: 'Conversation',
    type: 'mongoInternal',
    facets: [
        {name: 'comments', path: 'comments'},
    ],
    fields: [
        'text',
        {path: 'sender', type: "User", nullable: true},
        {path: "responseTo", type: 'object', nullable: true},
        {path: "parentMessage", type: 'object', nullable: true},
        {path: "seenBy", list: true},
        {path: "files", list: true, type: 'object'},
        {path: "timestamp"},
        'from'
        /*
        id: Date.now().toString(),
        text: newMessage,
        sender: currentUser,
        responseTo: replyMessage,
        parentMessage: messageDiscussion,
        timestamp: new Date().toISOString(),
         */
    ]
}

export const entity = {
    name: 'DemandCategory',
    facets: ['codeName'],
    fields: [
        'Category',
        {path: 'display', type: 'boolean'},
        {path: 'conversation', type: 'Conversation'}
    ],
    beforeSave: function(newObject, oldObject, context, callback) {
        const OID = new ObjectID()
        const systemMessage = {
            id: OID.toString(),
            text: "L'étape contrôle a été validé",
            sender: null,
            responseTo: null,
            parentMessage: null,
            seenBy: [],
            files: [],
            timestamp: new Date().toISOString(),
            from: 'system' // two values user or system
        }
        newObject.conversation.push(systemMessage)
        callback()
    },
    filters: [
        {
            name: "engagementProcessType",
            isDefault: false,
            query: function () {
                return {category: "2"}
            }
        },
    ]
}

export const module_ = {
    object: 'DemandCategory',
    category: {
        path: 'process',
        icon: 'repeat'
    },
    tKey: 'processType',
    defaultSortBy: 'name',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            'code',
            {
                path: 'name',
                type: 'translatedText'
            },
            {path: 'category', tKey: 'process'}
        ],
        form: {
            fields: [
                {path: 'code', editable: false},
                {
                    path: 'name',
                    type: 'textarea',
                    unique : true
                },
                {
                    path: 'category',
                    tKey: 'process',
                    default: {id:'1'},
                    subscriptions: {
                        onChange: (newValue, oldValue, {store, module, formInitialize, getObjectSuccessAction}) => {
                            const categoryField = module.viewMap.form.fields.find(field => field.path === 'category')
                            const displayField = module.viewMap.form.fields.find(field => field.path === 'display')
                            const category = categoryField && categoryField.getValue()
                            console.log('category', category)
                            store.dispatch(setFieldVisibility(displayField.id, category?.id === '2'))
                        }
                    }
                },
                {path: 'display', tKey: 'displayEngagementData'},
                {path: 'comments', type: 'comment2'},
                {
                    path: 'conversation',
                    type: 'conversation',
                    fieldPath: [
                        'id',
                        'text',
                        'sender.id', 'sender.name',
                        'seenBy',
                        'files',
                        "responseTo",
                        "parentMessage",
                        "timestamp",
                        'from'
                    ]
                }
            ],
            onOpen: ({ store, module, t }) => {
                const categoryField = module.viewMap.form.fields.find(field => field.path === 'category')
                const displayField = module.viewMap.form.fields.find(field => field.path === 'display')
                const category = categoryField && categoryField.getValue()
                console.log('category', category)
                store.dispatch(setFieldVisibility(displayField.id, category?.id === '2'))
            }
        }
    }
}
