import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from "lodash"
import PhoneInput from "react-phone-input-2"
import './Registration.css'
import DynamicStyledInput from "./Form/DynamicStyledInput"
import {colourStyles} from "../apps/KpModule/components/Filter/SelectStyles"
import {Select} from "./Form"
import DatePicker from "./Calendar/DatePicker"
import SpinningDots from "./Loader/SpinningDots"
import logoCNDA from  '../img/logo_cnda.svg'
import ValidationUI from "./ValidationUI"
import SimpleFiles from "./SimpleFiles";
import moment from "moment/moment";

const REFERENCE_DATE = moment().startOf('day')
function disabledDate(date) {
    return date
        ? moment(REFERENCE_DATE).diff(date.startOf('day'), 'days')  < 0
        : false
}

const removeElemAtIndex = (array, index) => {
    if(array.length <= index ) return array

    const halfBeforeTheUnwantedElement = array.slice(0, index)

    const halfAfterTheUnwantedElement = array.slice(index + 1);

    return halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);

}

const arrayToObject = (array= []) =>
    array.reduce((obj, item) => {
        obj[item.id] = item
        return obj
    }, {})

const FormContainer = (props) => {
    return (
        <div className="Registration-container">
            <div className="Registration-form-container">
                <div className= "Registration-well">
                    {props.children}
                </div>
            </div>
        </div>
    )
}


class Registration extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {
                civility: null,
                firstname: '',
                lastname: '',
                mail: '',
                phone: '',
                organizationName: '',
                organizationAddress: '',
                organizationAdditionalAddress: '',
                organizationZipCode: '',
                organizationCity: '',
                organizationCountry: 'FRANCE',
                organizationSize: null,
                rna: '',
                sheltersNumber: 0,
                generalInterestRecognition: null,
                publicUtilityRecognition: null,
                hasEndowmentFund: null,
                hasDUERP: null,
                DUERPUpdateDate: '',
                contactID: [],
                situationNotice: [],
                updatedStatus: [],
                balanceSheetsAndIncomeStatements: [],
                officialStatement: [],
                CERFA: [],
                DDPVisitReports: [],
                lastGeneralMeeting: []
            },
            open: {
                DUERPUpdateDateOpen: false
            }
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleFileUpload = this.handleFileUpload.bind(this)
        this.handleFileDeletion = this.handleFileDeletion.bind(this)
    }

    handleChange(field, value) {
        const result = {
            [field]: value
        }
        this.setState({
            data: {...this.state.data, ...result}
        })

        this.props.validateField(result)
    }
    handleOnOpenChange(path) {
        this.setState({
            open: {
                ...this.state.open,
                [path]: !this.state.open[path]
            }
        })
    }

    handleFileUpload(path,  file) {
        const result = {
            [path]: [...this.state.data[path], file]
        }
        this.setState({
            data: {
                ...this.state.data,
                ...result
            }
        })
        this.props.validateField(result)
    }

    handleFileDeletion(path, index) {
        console.log('files', this.state.data[path])
        const result = {
            [path]: removeElemAtIndex(this.state.data[path], index)
        }

        this.setState({
            data: {
                ...this.state.data,
                ...result
            }
        })
        this.props.validateField(result)
    }

    handleSubmit(event) {
        event.preventDefault()

        const { handleSubmit } = this.props
        handleSubmit && handleSubmit(this.state.data)
    }

    getSelectComponent(path, optionsPath, isMulti, removeLabel, label, placeholder) {
        const {t} = this.props
        const options = this.props[optionsPath]
        const optionsById = this.props[`${optionsPath}ById`]

        return <Select
            path={t(label || path)}
            options={options}
            noOptionsMessage={() => t('noOptions')}
            //className="Form-TagsField"
            isMulti={isMulti}
            styles={colourStyles}
            multi={true}
            required={true}
            onChange={optionOrOptions => {
                let selectedValue
                if(isMulti) {
                    selectedValue = optionOrOptions ? optionOrOptions.map(o => ({id: o.value})) : []
                } else {
                    selectedValue = optionOrOptions ? {id: optionOrOptions.value} : null
                }
                this.handleChange(path, selectedValue)
            }}
            value={
                isMulti
                    ? this.state.data[path].map(o => optionsById[o.id])
                    : optionsById[this.state.data[path]?.id]
            }
            isDisabled={false}
            isClearable={true}
            removeLabel={removeLabel}
            placeholder={t(placeholder || 'select')}
            t={t}
        />
    }

    render() {
        const {t, success, loading, validationErrors} = this.props
        const {
            firstname, lastname, mail, phone,
            organizationName, organizationAddress, organizationAdditionalAddress, organizationZipCode,
            organizationCity, rna, sheltersNumber,
            hasDUERP, DUERPUpdateDate,
            contactID, situationNotice, updatedStatus, balanceSheetsAndIncomeStatements, officialStatement,
            CERFA, DDPVisitReports, lastGeneralMeeting
        } = this.state.data

        const {DUERPUpdateDateOpen} = this.state.open

        if(success) {
            return (
                <FormContainer>
                    <img
                        id="logoCNDAo"
                        src={logoCNDA}
                        alt="logoCNDA"
                        style={{height: '150px'}}
                    />
                    <div style={{marginTop: '20px'}}>
                        <p>Vous venez de remplir le <b>volet administratif</b> de votre demande d’adhésion, première des cinq étapes nécessaires afin d’adhérer à une association <b>reconnue d’utilité publique</b>.</p>
                        <p>Nous avons le plaisir de vous annoncer que <b>votre demande est désormais enregistrée</b>. Un courriel de confirmation vient de vous être adressé.</p>
                        <p>Vous serez redirigé.e dans quelques instants vers notre site.</p>
                    </div>

                </FormContainer>
        )
        }

        if (loading) {
            return (
                <FormContainer>
                    <SpinningDots/>
                </FormContainer>
            )
        }

        return (
            <FormContainer>
                <img
                    id="logoCNDAo"
                    src={logoCNDA}
                    alt="logoCNDA"
                    style={{height: '150px'}}
                />
                <form onSubmit={this.handleSubmit} className="Registration-Form">
                    <div style={{marginBottom: '20px'}}>
                        <p>Vous souhaitez adhérer à la <b>C</b>onfédération <b>N</b>ationale <b>D</b>éfense de
                            l’<b>A</b>nimal et nous vous en remercions.</p>
                        <p>Reconnue d’utilité publique, la CNDA veille à ce que les associations qui la rejoignent, de même que les associations déjà confédérées, respectent un certain nombre de critères.</p>
                        <p>C’est la raison pour laquelle le processus d’adhésion comporte <b>cinq étapes</b>: volet administratif, volet technique, rencontre avec la CNDA, validation par le conseil d’administration de la CNDA et enfin paiement de la cotisation d’adhésion.</p>
                        <p>Bien sûr, nos équipes vous accompagneront tout au long de ce processus d’adhésion.</p>
                        <p>En remplissant le formulaire ci-dessous, vous allez démarrer le processus d’adhésion.</p>
                        <p>CNDA / Processus Adhésion.</p>
                    </div>
                    <div className="Registration-from-title">Processus d’adhésion - Volet administratif</div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div id="contact" className="section-title">Président(e)</div>

                        <div className="col-sm-12 col-md-12" style={{padding: '0'}}>
                            {this.getSelectComponent('civility', 'civilities', false, false, '')}
                        </div>
                        {!!validationErrors['civility'] && <ValidationUI t={t} error={validationErrors['civility']}/>}
                        <br/>
                        <DynamicStyledInput
                            id="RegistrationForm-firstname"
                            path="firstname"
                            type="text"
                            value={firstname}
                            required={true}
                            onChange={event => this.handleChange("firstname", event.target.value)}
                            t={t}
                        />
                        {!!validationErrors['firstname'] && <ValidationUI t={t} error={validationErrors['firstname']}/>}
                        <br/>
                        <DynamicStyledInput
                            id="RegistrationForm-lastname"
                            path="lastname"
                            type="text"
                            value={lastname}
                            required={true}
                            onChange={event => {
                                const uppercased = event.target.value?.toUpperCase()
                                this.handleChange("lastname", uppercased)
                            }}
                            t={t}
                        />
                        {!!validationErrors['lastname'] && <ValidationUI t={t} error={validationErrors['lastname']}/>}
                        <br/>
                        <DynamicStyledInput
                            id="RegistrationForm-mail"
                            path="Courriel"
                            type="text"
                            value={mail}
                            required={true}
                            onChange={event => this.handleChange("mail", event.target.value.toLowerCase())}
                            t={t}
                        />
                        {!!validationErrors['mail'] && <ValidationUI t={t} error={validationErrors['mail']}/>}
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>Téléphone*</div>
                        <PhoneInput
                            country="fr"
                            localization="fr"
                            placeholder="+33 X XX XX XX XX"
                            value={phone}
                            onChange={value => this.handleChange("phone", value)}
                            isValid={(inputNumber, country) => _.startsWith(inputNumber, country.dialCode)}
                            onlyCountries={['fr']}
                            specialLabel=""
                            inputClass="PhoneNumberInput-input"
                        />
                        {!!validationErrors['phone'] && <ValidationUI t={t} error={validationErrors['phone']}/>}
                        <br/>
                        <div id="organization" className="section-title">Association</div>
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="Raison sociale"
                            type="text"
                            value={organizationName}
                            onChange={event => this.handleChange("organizationName", event.target.value)}
                            required={true}
                            t={t}
                        />
                        {!!validationErrors['organizationName'] &&
                            <ValidationUI t={t} error={validationErrors['organizationName']}/>}
                        <br/>
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="address"
                            type="text"
                            value={organizationAddress}
                            onChange={event => this.handleChange("organizationAddress", event.target.value)}
                            required={true}
                            t={t}
                        />
                        {!!validationErrors['organizationAddress'] &&
                            <ValidationUI t={t} error={validationErrors['organizationAddress']}/>}
                        <br/>
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="additionalAddress"
                            type="text"
                            value={organizationAdditionalAddress}
                            onChange={event => this.handleChange("organizationAdditionalAddress", event.target.value)}
                            required={true}
                            t={t}
                        />
                        <br/>
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="zipCode"
                            type="text"
                            value={organizationZipCode}
                            onChange={event => this.handleChange("organizationZipCode", event.target.value)}
                            required={true}
                            t={t}
                        />
                        {!!validationErrors['organizationZipCode'] &&
                            <ValidationUI t={t} error={validationErrors['organizationZipCode']}/>}
                        <br/>
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="city"
                            type="text"
                            value={organizationCity}
                            onChange={event => {
                                const uppercased = event.target.value?.toUpperCase()
                                this.handleChange("organizationCity", uppercased)
                            }}
                            required={true}
                            t={t}
                        />
                        {!!validationErrors['organizationCity'] &&
                            <ValidationUI t={t} error={validationErrors['organizationCity']}/>}
                        <br/>
                        <div className="col-sm-12 col-md-12" style={{padding: '0'}}>
                            {this.getSelectComponent('organizationSize', 'organizationSizes', false, false)}
                        </div>
                        {!!validationErrors['organizationSize'] && <ValidationUI t={t} error={validationErrors['organizationSize']}/>}
                        <br/>
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="N° Registre National des Associations"
                            type="text"
                            value={rna}
                            onChange={event => this.handleChange("rna", event.target.value)}
                            required={true}
                            t={t}
                        />
                        {!!validationErrors['rna'] && <ValidationUI t={t} error={validationErrors['rna']}/>}
                        <br/>
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="Nombre de refuges"
                            type="number"
                            value={sheltersNumber}
                            onChange={event => this.handleChange("sheltersNumber", event.target.value)}
                            required={true}
                            t={t}
                        />
                        {!!validationErrors['sheltersNumber'] &&
                            <ValidationUI t={t} error={validationErrors['sheltersNumber']}/>}
                        <br/>

                        <div className="col-sm-12 col-md-12" style={{padding: '0'}}>
                            {this.getSelectComponent('generalInterestRecognition', 'yesNo', false, false, "Notre association est reconnue d’intérêt général", "Selectionner Oui ou Non")}
                        </div>
                        {!!validationErrors['generalInterestRecognition'] &&
                            <ValidationUI t={t} error={validationErrors['generalInterestRecognition']}/>}
                        <br/>
                        <div className="col-sm-12 col-md-12" style={{padding: '0'}}>
                            {this.getSelectComponent('publicUtilityRecognition', 'yesNo', false, false, "Notre association est reconnue d’utilité publique", "Selectionner Oui ou Non")}
                        </div>
                        {!!validationErrors['publicUtilityRecognition'] &&
                            <ValidationUI t={t} error={validationErrors['publicUtilityRecognition']}/>}
                        <br/>
                        <div className="col-sm-12 col-md-12" style={{padding: '0'}}>
                            {this.getSelectComponent('hasEndowmentFund', 'yesNo', false, false, "Notre association dispose d’un fonds de dotations", "Selectionner Oui ou Non")}
                        </div>
                        {!!validationErrors['hasEndowmentFund'] &&
                            <ValidationUI t={t} error={validationErrors['hasEndowmentFund']}/>}
                        <br/>
                        <div className="col-sm-12 col-md-12" style={{padding: '0'}}>
                            {this.getSelectComponent('hasDUERP', 'yesNo', false, false, "Notre association dispose d’un document unique des risques professionnels (DUERP)", "Selectionner Oui ou Non")}
                        </div>
                        {!!validationErrors['hasDUERP'] && <ValidationUI t={t} error={validationErrors['hasDUERP']}/>}
                        {
                            hasDUERP?.id === 'yes' && (
                                <>
                                    <br/>
                                    <DatePicker
                                        open={DUERPUpdateDateOpen}
                                        onOpenChange={() => this.handleOnOpenChange("DUERPUpdateDateOpen")}
                                        value={DUERPUpdateDate}
                                        onChange={value => this.handleChange("DUERPUpdateDate", value)}
                                        placeholder="Choisissez une date"
                                        disabledDate={disabledDate}
                                        required={true}
                                        path={"Date de mise à jour DUERP"}
                                        t={t}
                                    />
                                    {!!validationErrors['DUERPUpdateDate'] &&
                                        <ValidationUI t={t} error={validationErrors['DUERPUpdateDate']}/>}
                                </>
                            )
                        }
                        <br/>
                        <div id="documents" className="section-title">Documents</div>
                        <div style={{margin: '0px 5px 10px'}}>Pièce d’identité Président(e)*</div>
                        <SimpleFiles
                            url="publicFile"
                            allIds={contactID.map(file => file.id)}
                            byId={arrayToObject(contactID)}
                            push={file => this.handleFileUpload('contactID', file)}
                            remove={index => this.handleFileDeletion('contactID', index)}
                            editable={true}
                            anonymous={true}
                        />
                        {!!validationErrors['contactID'] && <ValidationUI t={t} error={validationErrors['contactID']}/>}
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>Avis de situation de moins de trois mois*</div>
                        <SimpleFiles
                            url="publicFile"
                            allIds={situationNotice.map(file => file.id)}
                            byId={arrayToObject(situationNotice)}
                            push={file => this.handleFileUpload('situationNotice', file)}
                            remove={index => this.handleFileDeletion('situationNotice', index)}
                            editable={true}
                            anonymous={true}
                        />
                        {!!validationErrors['situationNotice'] &&
                            <ValidationUI t={t} error={validationErrors['situationNotice']}/>}
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>Status à jour*</div>
                        <SimpleFiles
                            url="publicFile"
                            allIds={updatedStatus.map(file => file.id)}
                            byId={arrayToObject(updatedStatus)}
                            push={file => this.handleFileUpload('updatedStatus', file)}
                            remove={index => this.handleFileDeletion('updatedStatus', index)}
                            editable={true}
                            anonymous={true}
                        />
                        {!!validationErrors['updatedStatus'] &&
                            <ValidationUI t={t} error={validationErrors['updatedStatus']}/>}
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>Bilans et comptes de résultats des trois derniers
                            exercices*
                        </div>
                        <SimpleFiles
                            url="publicFile"
                            allIds={balanceSheetsAndIncomeStatements.map(file => file.id)}
                            byId={arrayToObject(balanceSheetsAndIncomeStatements)}
                            push={file => this.handleFileUpload('balanceSheetsAndIncomeStatements', file)}
                            remove={index => this.handleFileDeletion('balanceSheetsAndIncomeStatements', index)}
                            editable={true}
                            anonymous={true}
                        />
                        {!!validationErrors['balanceSheetsAndIncomeStatements'] &&
                            <ValidationUI t={t} error={validationErrors['balanceSheetsAndIncomeStatements']}/>}
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>
                            Déclaration officielle en Préfecture (au Tribunal Administratif pour Alsace et Moselle)*
                        </div>
                        <SimpleFiles
                            url="publicFile"
                            allIds={officialStatement.map(file => file.id)}
                            byId={arrayToObject(officialStatement)}
                            push={file => this.handleFileUpload('officialStatement', file)}
                            remove={index => this.handleFileDeletion('officialStatement', index)}
                            editable={true}
                            anonymous={true}
                        />
                        {!!validationErrors['officialStatement'] &&
                            <ValidationUI t={t} error={validationErrors['officialStatement']}/>}
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>
                            CERFA et le récépissé d’enregistrement de la dernière
                            liste des dirigeants déposée en Préfecture (au Tribunal Administratif pour Alsace et
                            Moselle)*
                        </div>
                        <SimpleFiles
                            url="publicFile"
                            allIds={CERFA.map(file => file.id)}
                            byId={arrayToObject(CERFA)}
                            push={file => this.handleFileUpload('CERFA', file)}
                            remove={index => this.handleFileDeletion('CERFA', index)}
                            editable={true}
                            anonymous={true}
                        />
                        {!!validationErrors['CERFA'] && <ValidationUI t={t} error={validationErrors['CERFA']}/>}
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>Deux derniers rapports de visite de la DDPP pour les
                            refuges (Si Applicable)
                        </div>
                        <SimpleFiles
                            url="publicFile"
                            allIds={DDPVisitReports.map(file => file.id)}
                            byId={arrayToObject(DDPVisitReports)}
                            push={file => this.handleFileUpload('DDPVisitReports', file)}
                            remove={index => this.handleFileDeletion('DDPVisitReports', index)}
                            editable={true}
                            anonymous={true}
                        />
                        {!!validationErrors['DDPVisitReports'] &&
                            <ValidationUI t={t} error={validationErrors['DDPVisitReports']}/>}
                        <br/>
                        <div style={{margin: '0px 5px 10px'}}>Convocation et le procès-verbal de la dernière Assemblée
                            Générale*
                        </div>
                        <SimpleFiles
                            url="publicFile"
                            allIds={lastGeneralMeeting.map(file => file.id)}
                            byId={arrayToObject(lastGeneralMeeting)}
                            push={file => this.handleFileUpload('lastGeneralMeeting', file)}
                            remove={index => this.handleFileDeletion('lastGeneralMeeting', index)}
                            editable={true}
                            anonymous={true}
                        />
                        {!!validationErrors['lastGeneralMeeting'] &&
                            <ValidationUI t={t} error={validationErrors['lastGeneralMeeting']}/>}
                        <br/>
                        <br/>
                        <button type="submit" className="btn Registration-mainButton remove-button-outline"
                                value="submit">
                            JE DÉMARRE LE PROCESSUS D’ADHÉSION
                        </button>
                    </div>
                </form>
            </FormContainer>
        )
    }
}

Registration.propTypes = {
    handleSubmit: PropTypes.func.isRequired
}

export default Registration

