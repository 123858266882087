import React, {useState, useEffect, useRef, useLayoutEffect, useCallback} from 'react';
import {
    ArrowRight,
    ChevronDown,
    ChevronLeft,
    ChevronsDown, ChevronUp,
    CornerDownLeft, Download, File, Maximize2,
    MessageCircle, Minimize2, X, Eye, Search, Minus
} from 'react-feather';
import './conversation2.css';
import { ObjectID } from 'bson'
import axios from "axios";
import {ProgressBar} from "react-bootstrap";
import {toastr} from "react-redux-toastr";
import {getFile} from "../../utils/network";
import moment from "moment/moment";
import _ from "lodash";
import MessageInput from "./utils/quillTextInput"
import FilePreviewHandler from "./utils/filePreview";
import DateRangePicker from "./utils/dateRangePicker";
import UserPicker from "./utils/userPicker";

const Conversation2 = ({
                           messages: initialMessages = [],
                           currentUser,
                           onSendMessage,
                           placeholder = "typeAMessage",
                           maxHeight = "440px",
                           minHeight = "60px",
                           t,
                           language,
                           groupModel,
                           push,
                           url
                       }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [replyMessage, setReplyMessage] = useState(null);
    const [filesToUpload, setFilesToUpload] = useState([]); // Track selected files
    const [messageDiscussion, setMessageDiscussion] = useState(null); // State for toggling views //showDiscussion setShowDiscussion
    const [activeMessageIndex, setActiveMessageIndex] = useState(0);
    const [highlightedMessageId, setHighlightedMessageId] = useState(null);
    const messagesEndRef = useRef(null);
    const specificMessageRef = useRef(null); // Ref for the specific message
    const [lastViewedMessageId, setLastViewedMessageId] = useState(null);  // Store message ID instead of DOM reference
    const shouldScrollRef = useRef(false);  // Flag to control when to scroll
    const [isMinimized, setIsMinimized] = useState(false); // Minimize state
    const [isSearching, setIsSearching] = useState(false); // Minimize state
    const [isMessagesCollapsed, setIsMessagesCollapsed] = useState(false);
    const [isDocumentsCollapsed, setIsDocumentsCollapsed] = useState(false);
    const [searchQuery, setSearchQuery] = useState({text: '', dateRange: { startDate: null, endDate: null }, sentBy: []}); // State for search input
    const [searchDateRange, setSearchDateRange] = useState({ startDate: null, endDate: null });
    const [fileUploadProgress, setFileUploadProgress] = useState(0);
    const [fileUploadError, setFileUploadError] = useState(null);
    const [initialUnseenMessages, setInitialUnseenMessages] = useState([]);

    const isMainDiscussion = !messageDiscussion
    const initialSubDiscussionOfSelectedMessage = !messageDiscussion ? [] : initialMessages.filter(msg => {
        return msg.parentMessage?.id === messageDiscussion.id
    })

    const subDiscussionOfSelectedMessage = isMainDiscussion ? [] : messages.filter(msg => msg.parentMessage?.id === messageDiscussion.id)
    const firstNonSeenMainMessage = initialMessages.filter(msg => !msg.parentMessage).find(msg => !msg.seenBy.includes(currentUser.id))
    const firstNonSeenSubMessage = initialSubDiscussionOfSelectedMessage.find(msg => !msg.seenBy.includes(currentUser.id))
    const firstNonSeenMessage = isMainDiscussion ? firstNonSeenMainMessage : firstNonSeenSubMessage

    // Initialize messages with initialMessages prop
    useEffect(() => {
        setMessages(initialMessages);
    }, [initialMessages]);

    //when first entering the component, we scroll into the first non seen msg, else we scroll to the end of the conversation
    useLayoutEffect(() => {
        const isInitialState = isMainDiscussion ?
            messages.length === initialMessages.length
            : subDiscussionOfSelectedMessage.length === initialSubDiscussionOfSelectedMessage.length
        if (isInitialState && !!firstNonSeenMessage) {
            //try this if the scroll propagated outside of the convo component
            const containerName = isMainDiscussion ?  '.conversation-messages' : '.conversation-subMessages'
            const container = document.querySelector(containerName);
            const element = document.getElementById(`message-${firstNonSeenMessage.id}`);
            if (element && container) {
                const containerRect = container.getBoundingClientRect();
                const elementRect = element.getBoundingClientRect();

                // Calculate the offset to center the element
                const containerHeight = containerRect.height;
                const elementOffset = elementRect.top - containerRect.top; // Offset relative to the container
                const elementHeight = elementRect.height;

                const offset = elementOffset - (containerHeight / 2) + (elementHeight / 2);

                // Apply the scroll
                container.scrollTop += offset;
            }
        } else {
            //scroll to the end of convo
            const containerName = isMainDiscussion ?  '.conversation-messages' : '.conversation-subMessages'
            const container = document.querySelector(containerName);
            if (container) {
                container.scrollTo({
                    top: container.scrollHeight, // Scroll to the bottom
                    behavior: isInitialState ? 'auto' : 'smooth',          // Enable smooth scrolling
                })
            }
        }

        //updating seen by
        messages.forEach(msg => {
            const notYetSeenByCurrentUser = !msg.seenBy.includes(currentUser.id)
            const mainOrSubDiscussionMessageCondition = isMainDiscussion ? !msg.parentMessage : msg.parentMessage?.id === messageDiscussion.id
            if(notYetSeenByCurrentUser && mainOrSubDiscussionMessageCondition){
                msg.seenBy.push(currentUser.id)
            }
        })
    }, [messages, isMainDiscussion]);

    // Single effect to handle scrolling when returning from discussion
    useLayoutEffect(() => {
        if (!messageDiscussion && lastViewedMessageId) {
            const element = document.getElementById(`message-${lastViewedMessageId}`);
            if (element) {
                scrollToAMessage(lastViewedMessageId)
            }
        }
    }, [messageDiscussion]);

    // Function to scroll to the last message
    const scrollToLastMessage = () => {
        const containerName = isMainDiscussion ?  '.conversation-messages' : '.conversation-subMessages'
        const container = document.querySelector(containerName);
        if (container) {
            container.scrollTo({
                top: container.scrollHeight, // Scroll to the bottom
                behavior: 'smooth',          // Enable smooth scrolling
            })
        }
    };

    const handleSend = () => {
        if (newMessage.trim()) {
            const OID = new ObjectID()
            const newMessageObject = {
                id: OID.toString(),
                text: newMessage,
                sender: currentUser,
                responseTo: replyMessage,
                parentMessage: messageDiscussion,
                seenBy: [currentUser.id],
                files: filesToUpload,
                timestamp: new Date().toISOString(),
                from: 'user' // two values user or system
            };
            setMessages([...messages, newMessageObject]);
            onSendMessage(newMessageObject); // Call the onSendMessage action
            setNewMessage('');
            setReplyMessage(null); // Clear the reply message after sending
            setFilesToUpload([])
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    const handleFileUpload = async (event) => {
        let filesToUploadPrep = []
        const files = Array.from(event.target.files); // Get all selected files
        if (files.length === 0) return;

        // Allow to re-upload the same file in a row
        event.target.value = null

        setFileUploadError(null);
        setFileUploadProgress(0);

        for (const file of files) {
            const data = new FormData();
            const fileName = `${moment().format('YYYY-MM-DD_HH:mm:ss')}_${file.name}`
            const fileType = file.type
            const fileSize = file.size
            const chunkSize = 15 * 1024 * 1024 // 15MB
            let fileChunk = null, chunkNb = 0

            for (let start = 0; start < fileSize; start += chunkSize, chunkNb++) {
                if (start + chunkSize > fileSize) {
                    fileChunk = file.slice(start, fileSize)
                }
                else {
                    fileChunk = file.slice(start, start + chunkSize)
                }
                data.append('fileChunks', fileChunk, fileName)
            }

            data.append('fileType', fileType)
            data.append('fileSize', fileSize)
            data.append('chunkNb', chunkNb)

            const options = {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: (progressEvent) => {
                    const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setFileUploadProgress(percent);
                },
            };
            try {
                const response = await axios.post(`/${url}`, data, options);
                const fileMessage = {
                    id: response.data.id,
                    name: response.data.filename,
                    originalName: file.name,
                    size: fileSize
                };
                filesToUploadPrep.push(fileMessage)
                //setMessages((prevMessages) => [...prevMessages, fileMessage]);
                toastr.success(`${file.name} uploaded successfully`);
            } catch (err) {
                const message = _.get(err, 'response.data');
                console.error(err);
                toastr.error(message || 'Error uploading file');
                setFileUploadError(message || 'Error uploading file. Please try again.');
            }
        }

        setFileUploadProgress(0);
        setFilesToUpload((prevFiles) => [...prevFiles, ...filesToUploadPrep]); // Append the new file
    };

    const handleDownload = (id, fileName) => {
        //password is undefined
        toastr.info('downloading')
        getFile({
            fileId: id,
            filename: fileName,
            password: undefined,
            language,
            groupModel
        })
    }

    const detachFile = (file) => {
        setFilesToUpload((prevFiles) => prevFiles.filter((fil) => fil.id !== file.id));
    }

    const handleReply = (replyMessage) => {
        setReplyMessage(replyMessage); // Set the message text being replied to
    };

    const handleStartDiscussion = (msg) => {
        setMessageDiscussion(msg)
        setReplyMessage(null);
        // Save current scroll position
        setLastViewedMessageId(msg.id);  // Store the ID of the message
    }

    const handleBackFromDiscussion = () => {
        setMessageDiscussion(null);
        setReplyMessage(null);
    }

    const toggleMinimize = () => {
        setIsSearching(false);
        setIsMinimized(!isMinimized);
    };

    const toggleIsSearching = () => {
        setIsSearching(!isSearching);
    };

    const toggleMessages = () => {
        setIsMessagesCollapsed(!isMessagesCollapsed);
    };

    const toggleDocuments = () => {
        setIsDocumentsCollapsed(!isDocumentsCollapsed);
    };

    // Single effect to handle scrolling when returning from discussion
    const scrollToAMessage = (msgID) => {
        if (msgID) {
            const element = document.getElementById(`message-${msgID}`);
            if (element) {
                // Highlight the message
                setHighlightedMessageId(msgID);

                element.scrollIntoView({
                    behavior: 'auto',
                    block: 'nearest', // Align to the center of the view for better visibility
                });

                setTimeout(() => {
                    setHighlightedMessageId(null);
                }, 500);
            }
        }
    };

    const getSortedUniqueParentMessages = (messages) => {
        const uniqueParentMessages = _.uniqBy(
            messages.filter(msg => !!msg.parentMessage && !msg.seenBy.includes(currentUser.id)).map((msg) => msg.parentMessage),
            'id'
        );

        return _.sortBy(uniqueParentMessages, (parentMsg) => new Date(parentMsg.timestamp)).reverse()
    };

    const sortedUniqueParentMessages = getSortedUniqueParentMessages(messages);
    console.log('letseee', activeMessageIndex, sortedUniqueParentMessages.length, activeMessageIndex >= sortedUniqueParentMessages.length)
    //activeMessageIndex goes from 0 to sortedUniqueParentMessages.length - 1
    if ( !!sortedUniqueParentMessages.length && activeMessageIndex >= sortedUniqueParentMessages.length ){
        setActiveMessageIndex(sortedUniqueParentMessages.length-1)
    }

    const navigateToPreviousMessage = () => {
        console.log('ok1')
        if (sortedUniqueParentMessages.length > 0){
            const lastActiveMessageIndex = sortedUniqueParentMessages.length - 1
            if (activeMessageIndex < lastActiveMessageIndex) {
                const previousIndex = activeMessageIndex + 1;
                setActiveMessageIndex(previousIndex);
                scrollToAMessage(sortedUniqueParentMessages[previousIndex]?.id);
            }
            else { // activeMessageIndex = lastActiveMessageIndex
                setActiveMessageIndex(activeMessageIndex);
                scrollToAMessage(sortedUniqueParentMessages[activeMessageIndex]?.id);
            }
        }
    };

    const navigateToNextMessage = () => {
        console.log('ok2')
        if (sortedUniqueParentMessages.length > 0){
            if (activeMessageIndex > 0) {
                const nextIndex = activeMessageIndex - 1;
                setActiveMessageIndex(nextIndex);
                scrollToAMessage(sortedUniqueParentMessages[nextIndex]?.id);
            }
            else { // activeMessageIndex = 0
                setActiveMessageIndex(activeMessageIndex);
                scrollToAMessage(sortedUniqueParentMessages[activeMessageIndex]?.id);
            }
        }
    };



    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const formatFileSize = (sizeInBytes) => {
        if (sizeInBytes >= 1024 * 1024 * 1024) {
            return (sizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
        } else if (sizeInBytes >= 1024 * 1024) {
            return (sizeInBytes / (1024 * 1024)).toFixed(2) + ' MB';
        } else if (sizeInBytes >= 1024) {
            return (sizeInBytes / 1024).toFixed(2) + ' KB';
        } else {
            return sizeInBytes + ' B';
        }
    };

    const mainMessages = messages.filter(msg => !msg.parentMessage)

    const mainMessagesGroupedByDay = _.groupBy(mainMessages, (message) => {
        const date = new Date(message.timestamp);
        const formattedDate = new Intl.DateTimeFormat('fr-FR', {
            weekday: 'short', // Abbreviated weekday (e.g., lun)
            day: '2-digit',   // Day in two digits
            month: '2-digit', // Month in two digits
            year: 'numeric'   // Full year
        }).format(date);

        // Capitalize the first letter
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    });

    const subMessagesOfAMainMessage = !messageDiscussion ? [] : messages.filter(msg => msg.parentMessage?.id === messageDiscussion.id || msg.id === messageDiscussion.id)
    const subMessagesGroupedByDay = _.groupBy(subMessagesOfAMainMessage, (message) => {
        const date = new Date(message.timestamp);
        const formattedDate = new Intl.DateTimeFormat('fr-FR', {
            weekday: 'short', // Abbreviated weekday (e.g., lun)
            day: '2-digit',   // Day in two digits
            month: '2-digit', // Month in two digits
            year: 'numeric'   // Full year
        }).format(date);

        // Capitalize the first letter
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    });


    const isTimestampInRange = (timestamp, startDate, endDate) => {

        // Si l'une des dates est null, impossible de faire la vérification
        if (!startDate || !endDate) return false;

        // Convertir le timestamp ISO en objet Date
        const date = new Date(timestamp);

        // Réinitialiser les heures/minutes/secondes pour comparer seulement les dates
        const normalizedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const normalizedStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        const normalizedEnd = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

        // Vérifier si la date est comprise entre la date de début et la date de fin (incluses)
        return normalizedDate >= normalizedStart && normalizedDate <= normalizedEnd;
    };

    let searchedMessages = []
    let searchedDocuments = messages.map(msg => msg.files.map(file => ({...file, timestamp: msg.timestamp, sender: msg.sender}))).flat()

    const isDoingTextSearch = searchQuery.text.length > 0
    const isDoingDateSearch = searchQuery.dateRange.startDate && searchQuery.dateRange.endDate
    const isDoingSentBySearch = searchQuery.sentBy.length > 0
    const isDoingASearch = isDoingTextSearch || isDoingDateSearch || isDoingSentBySearch

    if (isDoingASearch){
        searchedMessages = messages //initialization
        if (isDoingTextSearch) {
            searchedMessages = searchedMessages.filter( msg => msg.text.toLowerCase().includes(searchQuery.text.toLowerCase()))
            searchedDocuments = searchedDocuments.filter(file => file.originalName.toLowerCase().includes(searchQuery.text.toLowerCase()))
        }
        if (isDoingDateSearch) {
            searchedMessages = searchedMessages.filter(msg => isTimestampInRange(msg.timestamp, searchQuery.dateRange.startDate, searchQuery.dateRange.endDate))
            searchedDocuments = searchedDocuments.filter(file => isTimestampInRange(file.timestamp, searchQuery.dateRange.startDate, searchQuery.dateRange.endDate))
        }
        if (isDoingSentBySearch) {
            searchedMessages = searchedMessages.filter(msg => searchQuery.sentBy.map(user => user.id).includes(msg.sender?.id))
            searchedDocuments = searchedDocuments.filter(file => searchQuery.sentBy.map(user => user.id).includes(file.sender.id))
        }
    }
    const usersMessages = messages.filter(msg => msg.from === 'user')
    const usersData = _.uniqBy(usersMessages.map(msg => msg.sender).flat(), 'id')

    return (
        <div className="conversation-container">
            {!messageDiscussion ? (
                <>
                    <div className="searchBar">
                        <div className="buttons">
                            <Search className="searchButton" onClick={toggleIsSearching}></Search>
                            <button className="minimizeButton" title={isMinimized ? "Déplier" : "Replier"} onClick={toggleMinimize}>
                                {isMinimized ?
                                    <Maximize2 size={20} style={{flexShrink: 0}}/> : <Minimize2 size={20} style={{flexShrink: 0}}/>
                                }
                            </button>
                        </div>
                    </div>
                    <div className="container2">
                        <div className="container2-1">
                            <div
                                className="conversation-messages"
                                style={{
                                    maxHeight: maxHeight,
                                    height: isMinimized ? minHeight : maxHeight,
                                    transition: 'height 0.5s ease', // Smooth height transition
                                    willChange: 'height', // Add this
                                }}
                            >
                                {mainMessages.length === 0 ? (
                                        <div className="no-messages-placeholder">
                                            <p
                                                className="no-messages-placeholder"
                                            >
                                                Démarrer la discussion...
                                            </p>
                                        </div>
                                    ) :
                                    (
                                        Object.keys(mainMessagesGroupedByDay).map((date) => (
                                            <div key={date} className="grouped-messages">
                                                {/* Date Header */}
                                                <div className="message-date">{date}</div>
                                                {/* Messages */}
                                                {mainMessagesGroupedByDay[date].map((message) => (
                                                    <>
                                                        {firstNonSeenMainMessage?.id === message.id && (
                                                            <div className="nonSeenMessagesBadge">
                                                            <span
                                                                style={{display: "inlineBlock"}}>Nouveaux messages</span>
                                                            </div>)}
                                                        <div
                                                            key={message.id}
                                                            id={`message-${message.id}`}
                                                            className={`message-container 
                                                                ${message.from !== 'user' ? 'system' : message.sender.id === currentUser.id ? 'sent' : 'received'} 
                                                                ${highlightedMessageId === message.id ? 'highlighted' : ''
                                                            }`}
                                                        >
                                                            <div
                                                                className={`message-bubble 
                                                                    ${message.from !== 'user' ? 'system' : message.sender.id === currentUser.id ? 'sent' : 'received'}
                                                                `}
                                                            >
                                                                <div className="message-header">
                                                                    <span className="sender-name">{message.from !== 'user' ? 'Message plateforme' : message.sender.name}</span>
                                                                    {message.from === 'user' && (<CornerDownLeft
                                                                        className="replyIcon"
                                                                        size={18}
                                                                        onClick={() => handleReply(message)}
                                                                    />)}
                                                                </div>
                                                                {message.responseTo && (
                                                                    <div
                                                                        className={`replyMessage-header ${message.sender.id === currentUser.id ? 'sent' : 'received'}`}
                                                                        onClick={() => scrollToAMessage(message.responseTo.id)}
                                                                    >
                                                                        <span className="reply-sender-name">{message.responseTo.sender.name}</span>
                                                                        {/*<p className="message-text">{message.responseTo.text}</p>*/}
                                                                        <div className="message-text" dangerouslySetInnerHTML={{__html: message.responseTo.text}}/>
                                                                    </div>
                                                                )}
                                                                {/*<p className="message-text">{message.text}</p>*/}
                                                                <div className="message-text" dangerouslySetInnerHTML={{__html: message.text}}/>
                                                                {!!message.files.length && (
                                                                    <div className="messageFilesWrapper">
                                                                        {message.files.map((file) => (
                                                                            <FilePreviewHandler
                                                                                key={file.id}
                                                                                file={file}
                                                                                handleDownload={handleDownload}
                                                                                formatFileSize={formatFileSize}
                                                                                language={language}
                                                                                groupModel={groupModel}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                )}
                                                                <span className="message-time">{formatTime(message.timestamp)}</span>
                                                                {message.from === 'user' && (<button
                                                                    onClick={() => handleStartDiscussion(message)}
                                                                    className="leaveCommentButton"
                                                                >
                                                                    <div className="icon-with-badge">
                                                                        <MessageCircle className="icon-left" size={18}/>
                                                                        {
                                                                            messages.filter(msg => msg.parentMessage?.id === message.id && !msg.seenBy.includes(currentUser.id)).length > 0
                                                                            && (<span className="badge">
                                                                                {messages.filter(msg => msg.parentMessage?.id === message.id && !msg.seenBy.includes(currentUser.id)).length}
                                                                            </span>)
                                                                        }
                                                                    </div>
                                                                    <span className="button-text">Ouvrir la discussion</span>
                                                                    {
                                                                        messages.filter(msg => [msg.parentMessage?.id, msg.id].includes(message.id)).length > 1
                                                                        && (<span className="numberOfSubMessagesBadge">
                                                                            {messages.filter(msg => [msg.parentMessage?.id, msg.id].includes(message.id)).length}
                                                                        </span>)
                                                                    }
                                                                    <ArrowRight className="icon-right" size={18}/>
                                                                </button>)}
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        ))
                                    )
                                }
                            </div>
                            {/*{!isMinimized && [
                                sortedUniqueParentMessages.length > 0 && <div className="test">
                                    <button className="goUpButton" title="Naviguer vers la discussion active précedente"
                                            onClick={navigateToPreviousMessage}>
                                        <ChevronUp size={22} style={{flexShrink: 0}}/>
                                    </button>
                                    <div className="activeMessagesNavigationBadge">
                                        {`${sortedUniqueParentMessages.length - activeMessageIndex}/${sortedUniqueParentMessages.length}`}
                                    </div>
                                    < button className="goDownButton"
                                             title="Naviguer vers la discussion active suivante"
                                             onClick={navigateToNextMessage}>
                                        <ChevronDown size={22} style={{flexShrink: 0}}/>
                                    </button>
                                </div>,
                                <button className="goToTheEndButton" title="Naviguer vers la fin de la discussion"
                                        onClick={scrollToLastMessage}>
                                    <ChevronsDown size={24} style={{flexShrink: 0}}/>
                                </button>
                            ]}*/}
                            {/* Reply Preview Above Input */}
                            <div className="message-input-container">
                                {!!filesToUpload.length && (
                                    <div className="filesUploadPreview">
                                        <File className="FileIcon" size={18}
                                              style={{cursor: "default", flexShrink: 0}}/>
                                        <div className="file-items-wrapper">
                                            {filesToUpload.map((file, index) => (
                                                <div key={index} className="file-item">
                                                    <span className="file-name">{file.originalName}</span>
                                                    <span className="file-size">{formatFileSize(file.size)}</span>
                                                    <X className="deleteFileIcon" size={18} style={{flexShrink: 0}}
                                                       onClick={() => detachFile(file)}/>
                                                </div>
                                            ))}
                                        </div>
                                        {/* Clear reply on click */}
                                    </div>
                                )}
                                {replyMessage && (
                                    <div className="reply-preview">
                                        <button className="replyIcon2"><CornerDownLeft size={18}
                                                                                       style={{cursor: "default"}}/>
                                        </button>
                                        <div
                                            className={`replyMessage-header sent`}
                                        >
                                            <span className="reply-sender-name">{replyMessage.sender.name}</span>
                                            {/*<p className="message-text">{replyMessage.text}</p>*/}
                                            <div className="message-text"
                                                 dangerouslySetInnerHTML={{__html: replyMessage.text}}/>

                                        </div>
                                        <button className="cancelReplyButton" onClick={() => setReplyMessage(null)}>
                                            <X size={18}/>
                                        </button>
                                        {/* Clear reply on click */}
                                    </div>
                                )}
                                <div className="messageInputContainer">
                                    <MessageInput
                                        value={newMessage}
                                        onChange={setNewMessage}
                                        placeholder={t(placeholder)}
                                        required={false}
                                        path=""
                                        t={t}
                                        readOnly={false}
                                        disabled={false}
                                        onFileSelect={handleFileUpload}  // Pass the file upload handler
                                        handleSend={handleSend}
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            isSearching &&
                            <div className="container2-2">
                                <div className="container2-2-1">
                                    <span className="titleSpan">Rechercher des messages</span>
                                    <X className="xButton" onClick={() => setIsSearching(false)}></X>
                                </div>
                                <div className="container2-2-2">
                                    <UserPicker users={usersData} onChange={(users) => setSearchQuery({ ...searchQuery, sentBy: users })}/>
                                    <DateRangePicker onChange={(range) => setSearchQuery({ ...searchQuery, dateRange: range })}/>
                                    <input
                                        type="text"
                                        placeholder="Rechercher..."
                                        //value={searchQuery.text}
                                        onChange={(e) => setSearchQuery({ ...searchQuery, text: e.target.value })}
                                    />
                                </div>
                                <div className="container2-2-3">
                                    <div className="container2-2-3-1">
                                        <span className="titleSpan">Messages</span>
                                        <Minus className="minusButton" onClick={toggleMessages}></Minus>
                                    </div>
                                    {!isMessagesCollapsed && <div className={`container2-2-3-2`}>
                                        {
                                            searchedMessages.length > 0 ? (
                                                searchedMessages.map((message) => (
                                                    <div
                                                        key={'search'+message.id}
                                                        id={`searchMessage-${message.id}`}
                                                        className="searchedMessage"
                                                        onClick={() => scrollToAMessage( message.parentMessage?.id || message.id)}
                                                    >
                                                        <div className="searched-message-header">
                                                            <span className="sender-name">{message.from !== 'user' ? 'Message plateforme' : message.sender.name}</span>
                                                            <span className="searched-message-date">{moment(message.timestamp).format("DD/MM/YYYY")}</span>
                                                        </div>
                                                        <div
                                                            className="message-text"
                                                            dangerouslySetInnerHTML={{__html: message.text}}
                                                        />
                                                    </div>
                                                ))
                                            ) : (
                                                <p className="no-results">Aucun message trouvé</p>
                                            )
                                        }
                                    </div>}
                                </div>
                                <div className="container2-2-4">
                                    <div className="container2-2-4-1">
                                        <span className="titleSpan">Documents</span>
                                        <Minus className="minusButton" onClick={toggleDocuments}></Minus>
                                    </div>
                                    {!isDocumentsCollapsed && <div className={`container2-2-4-2`}>
                                        {
                                            searchedDocuments.length > 0 ? (
                                                <div className="searchedFilesWrapper">
                                                    {searchedDocuments.map((file) => (
                                                        <FilePreviewHandler
                                                            key={"search" + file.id}
                                                            file={file}
                                                            handleDownload={handleDownload}
                                                            formatFileSize={formatFileSize}
                                                            language={language}
                                                            groupModel={groupModel}
                                                        />
                                                    ))}
                                                </div>
                                            ) : (
                                                <p className="no-results">Aucun document trouvé</p>
                                            )
                                        }
                                    </div>}
                                </div>
                            </div>
                        }
                    </div>
                </>
            ) : (
                /* New Discussion View */

                <>
                    <div className="searchBar">
                        <button
                            onClick={() => handleBackFromDiscussion()}
                            className={`back-button expanding`}
                        >
                            <ChevronLeft className="icon-left" size={26} style={{flexShrink: 0, marginLeft: "-4px"}}/>
                        </button>
                        <div className="buttons">
                            <button className="minimizeButton" title={isMinimized ? "Déplier" : "Replier"}
                                    onClick={toggleMinimize}>
                                {isMinimized ?
                                    <Maximize2 size={20} style={{flexShrink: 0}}/> :
                                    <Minimize2 size={20} style={{flexShrink: 0}}/>
                                }
                            </button>
                        </div>
                    </div>
                    {/*<div className="backButtonContainer">
                        <button
                            onClick={() => handleBackFromDiscussion()}
                            className={`back-button expanding`}
                        >
                            <ChevronLeft className="icon-left" size={30} style={{flexShrink: 0, marginLeft: "-4px"}}/>
                        </button>
                    </div>*/}
                    {/*<button className="minimizeButton" title={isMinimized ? "Déplier" : "Replier"}
                            onClick={toggleMinimize}>
                        {isMinimized ? <Maximize2 size={20} style={{flexShrink: 0}}/> :
                            <Minimize2 size={20} style={{flexShrink: 0}}/>}
                    </button>*/}
                    {/*{!isMinimized &&
                        <button className="goToTheEndButton" title="Naviguer vers la fin de la discussion"
                                onClick={scrollToLastMessage}>
                            <ChevronsDown size={24} style={{flexShrink: 0}}/>
                        </button>
                    }*/}
                    <div className="conversation-subMessages"
                         style={{
                             maxHeight: maxHeight,
                             height: isMinimized ? minHeight : maxHeight,
                             transition: 'height 0.1s ease', // Smooth height transition
                             willChange: 'height', // Add this
                         }}
                    >
                        {
                            Object.keys(subMessagesGroupedByDay).map((date) => (
                                <div key={date} className="grouped-messages">
                                    {/* Date Header */}
                                    <div className="message-date"><span>{date}</span></div>
                                    {/* Messages */}
                                    {subMessagesGroupedByDay[date].map((message) => (
                                        <>
                                            {firstNonSeenSubMessage?.id === message.id && (
                                                <div className="nonSeenMessagesBadge">
                                                    <span style={{display: "inlineBlock"}}>Nouveaux messages</span>
                                                </div>)}
                                            <div
                                                key={message.id}
                                                id={`message-${message.id}`}
                                                className={`message-container ${message.sender.id === currentUser.id ? 'sent' : 'received'}`}
                                            >
                                                <div
                                                    className={`message-bubble ${message.sender.id === currentUser.id ? 'sent' : 'received'}`}>
                                                    <div className="message-header">
                                                        <span className="sender-name">{message.sender.name}</span>
                                                        <CornerDownLeft
                                                            className="replyIcon"
                                                            size={18}
                                                            onClick={() => handleReply(message)} // Set the reply message text
                                                        />
                                                    </div>
                                                    {message.responseTo && (
                                                        <div
                                                            className={`replyMessage-header ${message.sender.id === currentUser.id ? 'sent' : 'received'}`}>
                                                            <span
                                                                className="reply-sender-name"> {message.responseTo.sender.name} </span>
                                                            {/*<p className="message-text">{message.responseTo.text}</p>*/}
                                                            <div className="message-text"
                                                                 dangerouslySetInnerHTML={{__html: message.responseTo.text}}/>
                                                        </div>
                                                    )}
                                                    {/*<p className="message-text">{message.text}</p>*/}
                                                    <div className="message-text"
                                                         dangerouslySetInnerHTML={{__html: message.text}}/>
                                                    {!!message.files.length && (
                                                        <div className="messageFilesWrapper">
                                                            {message.files.map((file) => (
                                                                <FilePreviewHandler
                                                                    key={file.id}
                                                                    file={file}
                                                                    handleDownload={handleDownload}
                                                                    formatFileSize={formatFileSize}
                                                                    language={language}
                                                                    groupModel={groupModel}
                                                                />
                                                            ))}
                                                        </div>
                                                    )}
                                                    <span
                                                        className="message-time">{formatTime(message.timestamp)}</span>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            ))
                        }
                    </div>
                    {/* Reply Preview Above Input */}
                    <div className="message-input-container">
                        {!!filesToUpload.length && (
                            <div className="filesUploadPreview">
                                <File className="FileIcon" size={18} style={{cursor: "default", flexShrink: 0}}/>
                                <div className="file-items-wrapper">
                                    {filesToUpload.map((file, index) => (
                                        <div key={index} className="file-item">
                                            <span className="file-name">{file.originalName}</span>
                                            <span className="file-size">{(file.size / 1024).toFixed(2)} KB</span>
                                            <X className="deleteFileIcon" size={18} style={{flexShrink: 0}}
                                               onClick={() => detachFile(file)}/>
                                        </div>
                                    ))}
                                </div>
                                {/* Clear reply on click */}
                            </div>
                        )}
                        {replyMessage && (
                            <div className="reply-preview">
                                <button className="replyIcon2">
                                    <CornerDownLeft size={18} style={{cursor: "default"}}/>
                                </button>
                                <div
                                    className={`replyMessage-header sent`}
                                >
                                    <span className="reply-sender-name">{replyMessage.sender.name}</span>
                                    {/*<p className="message-text">{replyMessage.text}</p>*/}
                                    <div className="message-text"
                                         dangerouslySetInnerHTML={{__html: replyMessage.text}}/>
                                </div>
                                <button className="cancelReplyButton" onClick={() => setReplyMessage(null)}>
                                    <X size={18}/>
                                </button>
                                {/* Clear reply on click */}
                            </div>
                        )}
                        <MessageInput
                            value={newMessage}
                            onChange={setNewMessage}
                            placeholder={t(placeholder)}
                            required={false}
                            path=""
                            t={t}
                            readOnly={false}
                            disabled={false}
                            onFileSelect={handleFileUpload}  // Pass the file upload handler
                            handleSend={handleSend}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default Conversation2;