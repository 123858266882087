import React, { useState } from 'react';
import { Download, Eye, X } from 'react-feather';
import { toastr } from "react-redux-toastr";

const FilePreviewHandler = ({ file, handleDownload, formatFileSize, language, groupModel }) => {
    const [showPreview, setShowPreview] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [previewData, setPreviewData] = useState(null); // New state for text content
    const [isLoading, setIsLoading] = useState(false);

    const getFileType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        const imageTypes = ['jpg', 'jpeg', 'png'];
        const pdfTypes = ['pdf'];
        const textTypes = ['txt'];
        const csvType = ['csv'];


        if (imageTypes.includes(extension)) return 'image';
        if (pdfTypes.includes(extension)) return 'pdf';
        if (textTypes.includes(extension)) return 'text';
        if (csvType.includes(extension)) return 'csv';

        return 'unknown';
    };

    const parseCSV = (csvText) => {
        // Détecter le séparateur
        const firstLine = csvText.split('\n').find(line => line.trim() !== '');
        if (!firstLine) return [];

        const commaCount = (firstLine.match(/,/g) || []).length;
        const semicolonCount = (firstLine.match(/;/g) || []).length;
        const delimiter = semicolonCount > commaCount ? ';' : ',';

        const rows = csvText.split('\n').filter(row => row.trim() !== '');
        return rows.map(row => {
            // Simple CSV parsing (for more complex cases consider a library like PapaParse)
            const cells = row.split(delimiter).map(cell => cell.trim());
            return cells;
        });
    };

    const handlePreview = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(
                `${window.location.origin}/file?id=${file.id}&language=${language}&groupModelId=${groupModel?.id}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Credentials": true
                    },
                    method: 'GET',
                    credentials: 'same-origin'
                }
            );

            const blob = await response.blob();
            const fileType = getFileType(file.originalName);

            if (fileType === 'csv') {
                const text = await blob.text();
                setPreviewData(parseCSV(text));
                setPreviewUrl(null);
            } else if (fileType === 'text') {
                const text = await blob.text();
                setPreviewData(text);
                setPreviewUrl(null); // Clear image/pdf URL
            } else {
                const url = URL.createObjectURL(blob);
                setPreviewUrl(url);
                setPreviewData(null); // Clear previous text content
            }

            setShowPreview(true);
        } catch (error) {
            console.error('Error previewing file:', error);
            toastr.error('Erreur lors de la prévisualisation du fichier');
        } finally {
            setIsLoading(false);
        }
    };

    const closePreview = () => {
        setShowPreview(false);
        if (previewUrl) {
            URL.revokeObjectURL(previewUrl);
            setPreviewUrl(null);
        }
        setPreviewData(null); // Reset text content on close
    };

    const renderCSVTable = () => {
        if (!previewData || previewData.length === 0) return null;

        return (
            <div style={{ overflowX: 'auto', maxHeight: '80vh' }}>
                <table style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    margin: '1rem 0',
                    boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)'
                }}>
                    <thead>
                    <tr style={{
                        backgroundColor: '#009879',
                        color: '#ffffff',
                        textAlign: 'left'
                    }}>
                        {previewData[0].map((header, index) => (
                            <th key={index} style={{
                                padding: '12px 15px',
                                borderRight: '1px solid #dddddd'
                            }}>
                                {header}
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {previewData.slice(1).map((row, rowIndex) => (
                        <tr key={rowIndex} style={{
                            borderBottom: '1px solid #dddddd',
                            ':nth-of-type(even)': { backgroundColor: '#f3f3f3' }
                        }}>
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex} style={{
                                    padding: '12px 15px',
                                    borderRight: '1px solid #dddddd'
                                }}>
                                    {cell}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderPreview = () => {
        const fileType = getFileType(file.originalName);

        switch (fileType) {
            case 'image':
                return (
                    <img
                        src={previewUrl}
                        alt={file.originalName}
                        style={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain' }}
                    />
                );
            case 'pdf':
                return (
                    <iframe
                        src={previewUrl}
                        style={{ width: '100%', height: '80vh' }}
                        title={file.originalName}
                    />
                );
            case 'text':
                return (
                    <pre style={{
                        whiteSpace: 'pre-wrap',
                        overflowX: 'auto',
                        padding: '1rem',
                        backgroundColor: '#f5f5f5',
                        borderRadius: '4px',
                        maxHeight: '80vh',
                        margin: 0,
                        textAlign: 'left'
                    }}>
                        {previewData}
                    </pre>
                );
            case 'csv':
                return renderCSVTable();
            default:
                return (
                    <div style={{ textAlign: 'center', padding: '1rem', fontSize: '20px' }}>
                        Ce type de fichier ne peut pas être prévisualisé. Veuillez le télécharger pour le consulter.
                    </div>
                );
        }
    };

    return (
        <>
            <div className="messageFile">
                <Eye
                    size={18}
                    style={{ flexShrink: 0, cursor: 'pointer' }}
                    onClick={handlePreview}
                />
                <Download
                    size={18}
                    style={{ flexShrink: 0, cursor: 'pointer' }}
                    onClick={() => handleDownload(file.id, file.name)}
                />
                <span className="fileName">{file.originalName}</span>
                <span className="fileSize">{formatFileSize(file.size)}</span>
            </div>

            {showPreview && (
                <div className="preview-overlay" style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000
                }}>
                    <div className="preview-container" style={{
                        width: '80%',
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '8px',
                        maxWidth: '90%',
                        maxHeight: '100%',
                        overflow: 'auto',
                        position: 'relative'
                    }}>
                        <button
                            onClick={closePreview}
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '10px',
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                padding: '4px'
                            }}
                        >
                            <X size={24} />
                        </button>
                        <h3 style={{ marginBottom: '20px', paddingRight: '30px', textAlign: 'center' }}>{file.originalName}</h3>
                        {isLoading ? (
                            <div style={{ textAlign: 'center', padding: '2rem' }}>Loading preview...</div>
                        ) : (
                            renderPreview()
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default FilePreviewHandler;