const _ = require("lodash");
const moment = require("moment");
const async = require("async");
const {fieldPathJoinGetter} = require("../utils/kp3Utils");

const codeNameFields = function({codeUniqueWith, codeValidator, nameOptional, uniqueName}) {
    return [
        {
            path: "code",
            ps: {object: _.compact([codeValidator])},
            index: true,
            unique: true,
            notEmpty: true,
            uniqueWith: codeUniqueWith
        },
        {path: "name", unique: uniqueName, notEmpty: !nameOptional},
        fieldPathJoinGetter({path: "fullName", fieldPath: ["code", "name"]})
    ]
};

const firstLastNameFields = [
    {path: "firstName", ps: {object: ["notEmpty"]}},
    {path: "lastName", ps: {object: ["notEmpty"]}},
    {path: "name", f: function() { return this.firstName + " " + this.lastName; }}
];

const descriptionField = {path: "description", long: true};

const commentsField = {
    path: "comments",
    link: "OTM"
};

const conversationField = {
    path: "conversations",
    link: "OTM",
};

const filesField = {
    path: "files",
    link: "OTM",
    fieldPath: ["id", "filename", "date", "password"]
};

const dateField = {path: "date", type: "date"};

const commentEntity = {
    type: "mongoInternal",
    subType: "Comment", //kludge
    fields: [
        {path: "text"},
        {path: "date", ps: {object: [function(date) {
            if(! date) date = moment().format("YYYY-MM-DD HH:mm");
            return date;
        }]}},
        {path: "user", type: "object", nullable: true, ps: {object: [function(user) {
            if(!user && this.options.context && this.options.context.user && this.options.$v) {
                user = {id: this.options.context.user.id, name: this.options.context.user.name};
            }
            return user;
        }]}}
    ]
};

const conversationEntity = {
    type: "mongoInternal",
    subType: "Conversation",
    fields: [
        {path: "text"},
        {path: 'sender', type: "User", nullable: true},
        {path: "responseTo", type: 'object', nullable: true},
        {path: "parentMessage", type: 'object', nullable: true},
        {path: "seenBy", list: true},
        {path: "files", list: true, type: 'object'},
        {path: "timestamp"},
        {path: "from"}
    ]
};

const fileEntity = {
    type: "mongoInternal",
    subType: "File", //kludge
    fields: [
        {path: "filename"},
        {path: "date", ps: {object: [function(date) {
            if(! date) date = moment().format("YYYY-MM-DD HH:mm");
            return date;
        }]}},
        {path: "user", type: "object", nullable: true, ps: {object: [function(user) {
            if(! user && this.options.context && this.options.context.user) {
                user = {id: this.options.context.user.id, name: this.options.context.user.name};
            }
            return user;
        }]}},
        {path: "password"},
    ],
    afterSave(newObject, oldObject, callback) {
        console.log('afterSave')
        callback()
    }
};

export default [
    {name: "codeName", norm: {fields: [
        {path: "fields", default: [], $p: {$u: function(fields) {
            return _.cloneDeep(codeNameFields(this.options.facet)).concat(fields);
        }}}
    ]}},
    {name: "firstLastName", norm: {fields: [
        {path: "fields", default: [], $p: {$u: function(fields) {
            return _.cloneDeep(firstLastNameFields).concat(fields);
        }}}
    ]}},
    {name: "translatedField", norm: {fields: [
        {path: "fields", default: [], $p: {$u: function(fields) {

            // Field translation from field are typically used object names
            // we typically specify in the field name things like :
            //  en:: name
            //  fr:: shop
            const translateFromField = (object, context) => {
                const referenceField = this.options.facet.referenceField || "name";
                const translate = context.translateName || _.identity;
                return translate(object[referenceField]);
            };

            return [{
                path: `t${this.options.facet.referenceField ? _.upperFirst(this.options.facet.referenceField) : 'Name'}`,
                $f: (object, context, callback) => {
                    callback(null, translateFromField(object, context));
                }
            }].concat(fields);
        }}}
    ]}},
    {name: "description", norm: {fields: [
        {path: "fields", default: [], $p: {$u: function(fields) {
            return [_.cloneDeep(descriptionField)].concat(fields);
        }}}
    ]}},
    {name: "comments", norm: {fields: [
        {path: "fields", default: [], $p: {$f: function(fields, entity, entities) {
            const commentEntityName = this.options.facet.entityName || ("Comment" + entity.name);

            const thisCommentEntity = _.cloneDeep(commentEntity);
            thisCommentEntity.name = commentEntityName;
            thisCommentEntity.model = entity.model;

            const thisCommentsField = _.cloneDeep(commentsField);
            thisCommentsField.type = commentEntityName;

            entities.push(thisCommentEntity);
            fields.push(thisCommentsField);
        }}}
    ]}},
    {
        name: "conversations",
        norm: {
            fields: [
                {
                    path: "fields",
                    default: [],
                    $p: {
                        $f: function(fields, entity, entities) {
                            const conversationEntityName = this.options.facet.entityName || ("Conversation" + entity.name);

                            const thisConversationEntity = _.cloneDeep(conversationEntity);

                            thisConversationEntity.name = conversationEntityName;
                            thisConversationEntity.model = entity.model;

                            console.log('test', thisConversationEntity.name)


                            const thisConversationField = _.cloneDeep(conversationField);
                            thisConversationField.type = conversationEntityName;

                            entities.push(thisConversationEntity);
                            fields.push(thisConversationField);
                        }
                    }
                }
            ]
        }
    },
    {name: "files", linkType: "OTM", norm: {fields: [
        {path: "fields", default: [], $p: {$f: function(fields, entity, entities) {
            const linkType = this.options.facet.linkType;
            const path = this.options.facet.path;
            const fileEntityName = "File" + entity.name;

            const thisFileEntity = _.cloneDeep(fileEntity);
            thisFileEntity.name = fileEntityName;
            thisFileEntity.model = entity.model;

            const thisFilesField = _.cloneDeep(filesField);
            thisFilesField.type = fileEntityName;
            if(linkType === "OTO"){
                thisFilesField.link = linkType;
                thisFilesField.path = path || "file";
                thisFilesField.nullable = true;
            } else {
                thisFilesField.path = path || "files";
            }

            entities.push(thisFileEntity);
            fields.push(thisFilesField);
        }}}
    ]}},
    {name: "date", norm: {fields: [
        {path: "fields", default: [], $p: {$u: function(fields) {
            return [_.cloneDeep(dateField)].concat(fields);
        }}}
    ]}}
];
