import React, { useState, useRef, useEffect } from 'react';
import { User, Check, Search, X } from 'react-feather';

const UserPicker = ({ users = [], onChange, preselectedUsers = [] }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState(preselectedUsers);
    const [searchText, setSearchText] = useState('');
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toggleUser = (user) => {
        const isSelected = selectedUsers.some(u => u.id === user.id);
        let newSelectedUsers;

        if (isSelected) {
            newSelectedUsers = selectedUsers.filter(u => u.id !== user.id);
        } else {
            newSelectedUsers = [...selectedUsers, user];
        }

        setSelectedUsers(newSelectedUsers);

        if (onChange) {
            onChange(newSelectedUsers);
        }
    };

    const clearAllUsers = () => {
        setSelectedUsers([]);
        if (onChange) {
            onChange([]);
        }
    };

    const selectAllFilteredUsers = () => {
        const filteredUsers = users.filter(user =>
            user.name.toLowerCase().includes(searchText.toLowerCase())
        );

        // Combine existing selection with new filtered users
        const combined = [...selectedUsers];

        filteredUsers.forEach(user => {
            if (!combined.some(u => u.id === user.id)) {
                combined.push(user);
            }
        });

        setSelectedUsers(combined);

        if (onChange) {
            onChange(combined);
        }
    };

    const filteredUsers = users.filter(user =>
        user.name.toLowerCase().includes(searchText.toLowerCase())
    );

    const handleRemoveUser = (e, userId) => {
        e.stopPropagation();
        const newSelectedUsers = selectedUsers.filter(u => u.id !== userId);
        setSelectedUsers(newSelectedUsers);

        if (onChange) {
            onChange(newSelectedUsers);
        }
    };

    return (
        <div className="user-picker" ref={dropdownRef}>
            <div className="user-picker-input" onClick={toggleDropdown}>
                <div className="icon-container">
                    <User size={19} />
                </div>
                <div className="count-badge">
                    {selectedUsers.length > 0 && (
                        <span>{selectedUsers.length}</span>
                    )}
                </div>
            </div>

            {isOpen && (
                <div className="dropdown-menu">
                    <div className="dropdown-header">
                        <div className="search-container">
                            <Search size={18} />
                            <input
                                type="text"
                                placeholder="Rechercher des utilisateurs..."
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="dropdown-actions">
                        <button className="select-all" onClick={selectAllFilteredUsers}>
                            Tout sélectionner
                        </button>
                        <button className="clear-all" onClick={clearAllUsers}>
                            Effacer
                        </button>
                    </div>

                    <div className="users-list">
                        {filteredUsers.length > 0 ? (
                            filteredUsers.map(user => {
                                const isSelected = selectedUsers.some(u => u.id === user.id);
                                return (
                                    <div
                                        key={user.id}
                                        className={`user-item ${isSelected ? 'selected' : ''}`}
                                        onClick={() => toggleUser(user)}
                                    >
                                        <div className="user-avatar">
                                            {user.avatar ? (
                                                <img src={user.avatar} alt={user.name} />
                                            ) : (
                                                <div className="avatar-placeholder">
                                                    {user.name.charAt(0).toUpperCase()}
                                                </div>
                                            )}
                                        </div>
                                        <div className="user-info">
                                            <div className="user-name">{user.name}</div>
                                            {user.email && <div className="user-email">{user.email}</div>}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="no-results">Aucun utilisateur trouvé</div>
                        )}
                    </div>
                </div>
            )}

            <style jsx>{`
                .user-picker {
                    position: relative;
                    font-family: 'Inter', sans-serif;
                }

                .user-picker-input {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    padding: 8px 6px;
                    border: 1px solid #e0e0e0;
                    border-radius: 8px;
                    background-color: white;
                    cursor: pointer;
                    min-height: 38px;
                    width: 100%;
                    height: 100%
                }

                .icon-container {
                    display: flex;
                    align-items: center;
                    color: #666;
                }

                .selected-users {
                    flex-grow: 1;
                    overflow: hidden;
                }

                .placeholder {
                    color: #9ca3af;
                    font-size: 14px;
                }

                .user-pills {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 4px;
                    max-width: 100%;
                }

                .user-pill {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    background-color: #f3f4f6;
                    border-radius: 4px;
                    padding: 2px 6px 2px 8px;
                    font-size: 12px;
                }

                .remove-user {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    background: none;
                    cursor: pointer;
                    padding: 2px;
                    border-radius: 50%;
                    color: #666;
                }

                .remove-user:hover {
                    background-color: #e5e7eb;
                }

                .count-badge {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f3f4f6;
                    border-radius: 10px;
                    padding: 0 6px;
                    min-width: 18px;
                    height: 18px;
                    font-size: 12px;
                    font-weight: 500;
                }

                .dropdown-menu {
                    display: block;
                    position: absolute;
                    top: calc(100% + 4px);
                    left: 0;
                    //left: 50%;
                    //transform: translateX(-50%);
                    z-index: 100;
                    width: 320px;
                    background-color: white;
                    border-radius: 8px;
                    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
                    overflow: hidden;
                }

                .dropdown-header {
                    padding: 7px 12px 12px 12px;
                    border-bottom: 1px solid #f0f0f0;
                }

                .search-container {
                    display: flex;
                    align-items: center;
                    padding: 6px 8px;
                    border: 1px solid #e0e0e0;
                    border-radius: 6px;
                    background-color: #f9fafb;
                }

                .search-container input {
                    flex-grow: 1;
                    border: none;
                    background: transparent;
                    outline: none;
                    font-size: 14px;
                }

                .dropdown-actions {
                    display: flex;
                    justify-content: space-between;
                    padding: 8px 12px;
                    border-bottom: 1px solid #f0f0f0;
                }

                .select-all, .clear-all {
                    background: none;
                    border: none;
                    font-size: 12px;
                    color: #3b82f6;
                    cursor: pointer;
                    padding: 2px 4px;
                }

                .select-all:hover, .clear-all:hover {
                    text-decoration: underline;
                }

                .users-list {
                    max-height: 300px;
                    overflow-y: auto;
                }

                .user-item {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 8px 12px;
                    cursor: pointer;
                    transition: background-color 0.15s;
                }

                .user-item:hover {
                    background-color: #f9fafb;
                }

                .user-item.selected {
                    background-color: #BBDEFB
                }

                .user-avatar {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    overflow: hidden;
                    background-color: #e5e7eb;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .avatar-placeholder {
                    color: #6b7280;
                    font-weight: 500;
                    font-size: 14px;
                }

                .user-avatar img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .user-info {
                    flex: 1;
                    min-width: 0;
                }

                .user-name {
                    font-size: 14px;
                    font-weight: 500;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .user-email {
                    font-size: 12px;
                    color: #6b7280;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .user-item.selected .checkbox {
                    background-color: #3b82f6;
                    border-color: #3b82f6;
                }

                .no-results {
                    text-align: center;
                    color: #6b7280;
                    font-size: 14px;
                }
            `}</style>
        </div>
    );
};

export default UserPicker;