import React, { useState, useRef, useEffect } from 'react';
import {Calendar, X, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight} from 'react-feather';

const DateRangePicker = ({ onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
    });
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectionMode, setSelectionMode] = useState('start'); // 'start' or 'end'
    const calendarRef = useRef(null);

    // Fermeture du calendrier si on clique à l'extérieur
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Formater la date pour l'affichage
    const formatDate = (date) => {
        if (!date) return '';
        return date.toLocaleDateString('fr-FR');
    };

    // Afficher la plage de dates
    const displayDateRange = () => {
        if (!dateRange.startDate && !dateRange.endDate) return 'Sélectionner des dates';
        if (dateRange.startDate && !dateRange.endDate) return formatDate(dateRange.startDate);
        return `${formatDate(dateRange.startDate)} - ${formatDate(dateRange.endDate)}`;
    };

    // Vérifier si une date est dans la plage sélectionnée
    const isInRange = (date) => {
        if (!dateRange.startDate || !dateRange.endDate) return false;
        return date > dateRange.startDate && date < dateRange.endDate;
    };

    // Vérifier si une date est la date de début ou de fin
    const isStartOrEndDate = (date) => {
        if (!date) return false;
        return (
            (dateRange.startDate && date.getTime() === dateRange.startDate.getTime()) ||
            (dateRange.endDate && date.getTime() === dateRange.endDate.getTime())
        );
    };

    // Obtenir le nombre de jours dans un mois
    const getDaysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    // Obtenir le premier jour du mois (0 = dimanche, 1 = lundi, etc.)
    const getFirstDayOfMonth = (year, month) => {
        return new Date(year, month, 1).getDay();
    };

    // Mois précédent
    const prevMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1));
    };

    // Mois suivant
    const nextMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1));
    };

    // Année précédente
    const prevYear = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear() - 1, currentMonth.getMonth()));
    };

    // Année suivante
    const nextYear = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear() + 1, currentMonth.getMonth()));
    };

    // Gérer la sélection d'une date
    const handleDateClick = (day) => {
        const clickedDate = new Date(
            currentMonth.getFullYear(),
            currentMonth.getMonth(),
            day
        );

        if (selectionMode === 'start') {
            // Mise à jour pour la date de début
            const newRange = {
                startDate: clickedDate,
                endDate: null,
            };
            setDateRange(newRange);
            setSelectionMode('end');
        } else {
            // Mise à jour pour la date de fin
            let newRange;

            // S'assurer que la date de fin est après la date de début
            if (clickedDate < dateRange.startDate) {
                newRange = {
                    startDate: clickedDate,
                    endDate: dateRange.startDate,
                };
            } else {
                newRange = {
                    startDate: dateRange.startDate,
                    endDate: clickedDate,
                };
            }

            // Mettre à jour l'état local
            setDateRange(newRange);
            setSelectionMode('start');

            // Notifier le parent des changements avec la nouvelle plage
            // Important: on passe newRange et non pas dateRange
            if (onChange) {
                onChange(newRange);
            }

            // Fermer après une courte pause
            setTimeout(() => {
                setIsOpen(false);
            }, 300);
        }
    };

    // Effacer les dates sélectionnées
    const clearDates = () => {
        setDateRange({ startDate: null, endDate: null });
        setSelectionMode('start');
    };

    // Noms des mois en français
    const monthNames = [
        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];

    // Noms des jours en français
    const dayNames = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];

    // Rendu du calendrier
    const renderCalendar = () => {
        const year = currentMonth.getFullYear();
        const month = currentMonth.getMonth();
        const daysInMonth = getDaysInMonth(year, month);
        const firstDayOfMonth = getFirstDayOfMonth(year, month);

        const days = [];

        // Jours vides pour l'alignement
        for (let i = 0; i < firstDayOfMonth; i++) {
            days.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
        }

        // Jours du mois
        for (let day = 1; day <= daysInMonth; day++) {
            const date = new Date(year, month, day);
            const isSelected = isStartOrEndDate(date);
            const isRangeDay = isInRange(date);

            days.push(
                <div
                    key={day}
                    className={`calendar-day ${isSelected ? 'selected' : ''} ${isRangeDay ? 'in-range' : ''}`}
                    onClick={() => handleDateClick(day)}
                >
                    {day}
                </div>
            );
        }

        return days;
    };

    return (
        <div className="date-range-picker">
            <div className="date-picker-input" onClick={() => setIsOpen(!isOpen)}>
                <Calendar size={18} />
            </div>

            {isOpen && (
                <div className="calendar-dropdown" ref={calendarRef}>
                    <div className="calendar-header">
                        <div className="calendar-chevrons">
                            <ChevronsLeft size={22} onClick={prevYear}></ChevronsLeft>
                            <ChevronLeft size={20} onClick={prevMonth}></ChevronLeft>
                        </div>
                        <span>{`${monthNames[currentMonth.getMonth()]} ${currentMonth.getFullYear()}`}</span>
                        <div className="calendar-chevrons">
                            <ChevronRight size={20} onClick={nextMonth}></ChevronRight>
                            <ChevronsRight size={22} onClick={nextYear}></ChevronsRight>
                        </div>
                    </div>

                    <div className="calendar-days-header">
                        {dayNames.map((day) => (
                            <div key={day} className="day-name">
                                {day}
                            </div>
                        ))}
                    </div>

                    <div className="calendar-days">{renderCalendar()}</div>

                    <div className="calendar-footer">
                        <div className="selection-info">
                            {selectionMode === 'start' ? 'Sélectionnez la date de début' : 'Sélectionnez la date de fin'}
                        </div>
                        <button className="clear-button" onClick={clearDates}>
                            <X size={14} /> Effacer
                        </button>
                    </div>
                </div>
            )}

            <style jsx>{`
        .date-range-picker {
          position: relative;
        }

        .date-picker-input {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 10px 12px;
          border: 1px solid #e0e0e0;
          border-radius: 8px;
          background-color: white;
          cursor: pointer;
          height: 100%;
        }
        
        .calendar-dropdown {
          position: absolute;
          top: calc(100% + 4px);
          left: 50%;
          transform: translateX(-27%);
          z-index: 100;
          width: 300px;
          background-color: white;
          border-radius: 8px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          padding: 12px;
        }

        .calendar-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
        }
        
        .calendar-chevrons{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .calendar-header button {
          border: none;
          background: none;
          cursor: pointer;
          width: 28px;
          height: 28px;
          border-radius: 4px;
        }

        .calendar-header button:hover {
          background-color: #f5f5f5;
        }

        .calendar-days-header {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          gap: 4px;
          margin-bottom: 8px;
        }

        .day-name {
          text-align: center;
          font-size: 12px;
          color: #666;
          padding: 4px 0;
        }

        .calendar-days {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          gap: 4px;
        }

        .calendar-day {
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          cursor: pointer;
          border-radius: 4px;
        }

        .calendar-day:hover {
          background-color: #f5f5f5;
        }

        .calendar-day.empty {
          pointer-events: none;
        }

        .calendar-day.selected {
          background-color: #3b82f6;
          color: white;
        }

        .calendar-day.in-range {
          background-color: #e0edff;
        }

        .calendar-footer {
          display: flex;
          justify-content: space-between;
          margin-top: 12px;
          align-items: center;
        }

        .selection-info {
          font-size: 12px;
          color: #666;
        }

        .clear-button {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 6px;
          border: none;
          background-color: #f3f4f6;
          padding: 6px 10px 6px 6px;
          border-radius: 4px;
          font-size: 12px;
        }

        .clear-button:hover {
          background-color: #e5e7eb;
        }
      `}</style>
        </div>
    );
};

export default DateRangePicker;